.footer_style {
  background-color: transparent !important;
}

#main-content {
  background-image: linear-gradient(19deg, #fff 44%, #ffe5d9 93%);
}

.subtitle {
  max-width: 480px;

  @include media-breakpoint-down('sm') {
    max-width: 350px;
    margin: auto;
  }
}

.epargne-slider {
  position: relative;

  .tns-ovh {
    padding: 0.25rem 0; // overflow hidden & box-shadow sur les card
  }

  .slides {
  }

  > .controls {
    display: flex;

    > .prev,
    > .next {
      z-index: 2;
      display: flex;
      align-items: center;
      transition: color 0.2s, transform 0.2s ease, opacity 0.2s 0.2s;

      &:hover,
      &:focus {
        color: $corail_mh_brand;
        transform: scale(1.1);
      }
    }

    > .prev {
      left: -3rem;
      justify-content: flex-end;
      margin: 32px 48px 32px auto;
    }

    > .next {
      right: -3rem;
      margin: 32px auto 32px 48px;
    }
  }

  .slide {
    padding: 0 1rem;
  }

  .slider-card {
    overflow: hidden;
    background-color: #fff;

    .thumb {
      margin: 0 auto;
      width: 63%;
      padding-top: 50.85%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:focus,
    &:hover,
    &:focus-within {
      box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.3) !important;
    }
  }
}

.footer-img {
  line-height: 0;
  margin: 0 72px 0 92px;

  @include media-breakpoint-down('sm') {
    margin: 0;
  }
}


.slide-indicators {
  margin: -47px 0 0 0;
  position: relative;
  bottom: 1px;

  span {
    text-indent: 0;
    width: 8px;
    height: 8px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 50%;
    background: $gris_dark;
    opacity: 1;
    transition: $gris_dark 0.4s;

    &.active {
      background: $corail_mh_brand;
      transition: $gris_dark 0.2s;
      width: 30px;
      height: 8px;
      border-radius: 4px;
    }
  }
}


/* Styles des puces colorées */
.bullet.pee {
  background: linear-gradient(227deg, #3d44c9 100%, #5f65e3 3%);
}

.bullet.perco {
  background: linear-gradient(225deg, #ffdc6e, #f9bd00);
}

.bullet.pero {
  background: linear-gradient(225deg, #b50d3a, #de2b5b);
}

.bullet.perin {
  background: linear-gradient(225deg, #007286, #008299);
}

.bullet.per-collectif {
  background: linear-gradient(225deg, #aeffef, #03dfb2);
}

.bullet.ccb {
  background: linear-gradient(225deg, #ffa51b, #ff8347);
}

.bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Mise en page des items */
.contract-item {
  display: flex;
  align-items: center;
  max-width: 200px;
  flex: 1 1 auto;
}

.contract-text {
  font-size: 16px;
  color: #000;
}

/* Styles pour les icônes d'information */
.dsicon-cercle-information {
  margin-left: 4px;
}

.toolti {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #000;
}

.toolti .tooltitext {
  visibility: hidden;
  white-space: nowrap;
  background-color: #c1d5ee;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 10px;
  font-size: 14px;
  position: absolute;
  z-index: 1;
  bottom: calc(120% + 10px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.toolti:hover .tooltitext {
  visibility: visible;
  opacity: 1;
}



/* Responsive - Mobile */
/* Grille sur desktop */
.contract-types .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.col-4 {
  flex: 0 0 calc(25% - 36px);
  max-width: calc(25% - 0px);
  flex-grow: 0;
}

/* Responsive - Mobile */
@media (max-width: 768px) {
  .contract-types .row {
  }

  /* Trois éléments par ligne sur mobile */
  .col-4 {
    flex: 0 0 calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
    flex-grow: 0;
  }

  .contract-text {
    font-size: 14px; /* Réduction de la taille des textes */
  }

  /* Cacher les icônes sur mobile */
  .dsicon-cercle-information {
    display: none !important;
  }
}

/* Responsive - Desktop (taille >= 769px) */
@media (min-width: 769px) {
  .dsicon-cercle-information {
    display: inline-block !important;
  }
}


/* Responsive - Desktop */
@media (min-width: 769px) {
  .dsicon-cercle-information {
    display: inline-block !important;
  }
}
.titre-text {
  margin-left: 33px;
}

.desktop-epargne-img {
  margin-right: 8%;
  width: 543px;
  height: 450px;
  @include media-breakpoint-up('xl') {
    width: 24%;
    margin-left: 14%;
    height: 450px;
  }
  img {
    height: 450px;
    width: 543px;
    max-width: none;
    @include media-breakpoint-up('xl') {
      height: 450px;
    }
  }
}

.mobile-epargne-img {
  width: 240px;
  height: 290px;
  margin: auto;
  object-fit: contain;
}
